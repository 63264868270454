import request from '@/utils/request'

//获取车辆信息
export function getCarList(id) {
    return request({
        url: `customer-service/vehicle/pageMng?pageSize=${id.pageSize}&pageNum=${id.pageNumber}&phone=${id.phone}&number=${id.number}`,
        method: 'get',
    })
}
//获取停车订单信息
export function getParkingList(id) {
    return request({
        url: `/customer-service/vehicle/payRecordManagerPage?body=${id.body}&pageSize=${id.pageSize}&pageNum=${id.pageNumber}&mobile=${id.phone}&transactionId=${id.transactionId}&orderNumber=${id.orderNumber}&extendOrderNumber=${id.extendOrderNumber}&dealStatus=${id.dealStatus}&payStartTime=${id.payStartTime}&payEndTime=${id.payEndTime}&createStartTime=${id.createStartTime}&createEndTime=${id.createEndTime}`,
        method: 'get',
    })
}

//获取停车缴费信息列表
export function queryParkingInfo(params) {
    return request({
        url: `/small-inteface/parking/queryParkingInfo`,
        method: 'post',
        data: params,
    })
}

//获取停车缴费统计
export function parkingInfoStatistics(params) {
    return request({
        url: `/small-inteface/parking/parkingInfoStatistics`,
        method: 'post',
        data: params,
    })
}

//导出
export function exportParkingInfo(params) {
    return request({
        url: `/small-inteface/parking/exportXls`,
        method: 'post',
        data: params,
    })
}
