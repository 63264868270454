<template>
<!-- 车辆管理，查询车牌号 -->
  <div class="main">
    <!-- 头部搜索栏 -->
    <div class="header main">
      <div class="formBox">
            <label class="label">车牌号:</label>
            <el-input
            placeholder="请输入车牌号"
            class="search-input ml-15"
            clearable
            v-model.trim="searchObj.number"
            ></el-input>
      </div>
      <div class="formBox">
            <label class="label">手机号:</label>
            <el-input
            placeholder="请输入手机号"
            class="search-input ml-15"
            clearable
            v-model.trim="searchObj.phone"
            ></el-input>
      </div>
      <el-button type="primary" icon="el-icon-upload2" @click="getData()">查询</el-button> 
      <el-button type="primary" icon="el-icon-refresh" @click="refresh()">重置</el-button>     
    </div>
    <div class="body main">
      <el-table
       :data="tableData">
       <el-table-column prop="userName" label="用户名称" width="180">
       </el-table-column>
       <el-table-column prop="phone" label="用户手机号码" width="180">
       </el-table-column>
       <el-table-column prop="number" label="车牌" width="180">
       </el-table-column>
       <el-table-column prop="brand" label="品牌" width="180">
       </el-table-column>
       <el-table-column prop="color" label="颜色" width="180">
       </el-table-column>
       <el-table-column prop="carModel" label="车型号" width="180">
       </el-table-column>
      </el-table> 
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumber"
        :page-sizes="[10,20,50,100]"
        :page-size="pageSize"
        layout="total,sizes,prev,pager,next, jumper"
        :total="total">
      </el-pagination>  
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination/index";
import{
  getCarList,
} from "@/api/service_center/parking"

export default {
  name: "parking_fee",
  components: {
    Pagination
  },
  data() {
    return {
     searchObj:{
       phone:'',
       number:''
     },
     tableData:[],
    //分页
    pageNumber:1,
    pageSize:10,
    total:0

  }
  },
  created() {
  },
  mounted(){
    this.getData();
  },
  methods: {
    getData(){
      let params = {
        phone:this.searchObj.phone,
        number:this.searchObj.number,
        pageSize:this.pageSize,
        pageNumber:this.pageNumber
      }
      getCarList(params).then((res)=>{
        if(res.data.code == 200){
          this.tableData = res.data.body.list
          this.total = Number(res.data.body.total)
        }
      }).catch((e)=>{
        console.log(e)
      })
    },
    // 重置
    refresh(){
      this.searchObj.phone = '';
      this.searchObj.number = '';
      this.pageNumber = 1
      this.pageSize = 10
      this.getData();
    },
    // 分页
    handleSizeChange(e){
      this.pageSize =e
      this.getData();
    },
    handleCurrentChange(e){
      this.pageNumber = e
      this.getData();
    }
  }
};
</script>
<style lang="scss" scoped>
.main{
  width: 100%;
}
.header{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 12px;
  padding: 36px 0;
  border-bottom: 1px solid grey;
  .formBox{
    width: 25%;
    margin-left:24px;
  }
}
.body{
    width: 96%;
    margin: 0 auto;
  }
</style>
